import { useEffect, useRef, useState } from "react";

import { Box, Button, Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { AthleteInfo, AthleteProfile } from "@shared/athleteModels";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppStore } from "../../../app-store";
import DynamicForm, { FormFieldConfig } from "../../../components/DynamicForm";
import { Page } from "../../../components/Page";
import {
  athleteBasicInfoFieldsToEdit,
  athleteFormConfig,
  AthleteInfoEditableFields,
  educationLevelOptions,
  majorOptions,
  preferredEmploymentOptions,
  preferredWorkEnvironmentOptions,
  stateOptions,
} from "../../../config/athleteFormConfig";
import { useAthleteInfoMutation, useAthleteProfile } from "../../../hooks/useAthleteProfile";
import { useAthleteProfileBuilderData } from "../../../hooks/useAthleteProfileBuilderData";

interface BuildAthleteInfoProps {
  onClose: () => void;
}

export function BuildAthleteInfo({ onClose }: BuildAthleteInfoProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewText, setReviewText] = useState("");
  const [subheadingText, setSubheadingText] = useState(
    "Your skills and experiences make you stand out, but some of these basic fields help us get you in front of the right people quickly.",
  );
  const [headingText, setHeadingText] = useState("Helping You Get Found");
  const [isOnboarding] = useAppStore((state) => [state.isOnboarding]);
  const [athleteResumeData] = useAppStore((state) => [state.athleteResumeData]);
  const { data: athleteProfile, isSuccess: isSuccessAthleteProfile } = useAthleteProfile();
  const { mutate: updateAthleteInfo } = useAthleteInfoMutation(onClose);

  const formRef = useRef<HTMLFormElement>(null);
  const formMethods = useForm<AthleteInfo>();
  const [formConfigData, setFormConfigData] =
    useState<Record<keyof AthleteInfo, FormFieldConfig<AthleteInfo[keyof AthleteInfo]>>>(athleteFormConfig);
  const formValues = formMethods.watch();

  useEffect(() => {
    if (!athleteResumeData) return;
    if (athleteProfile && athleteProfile.displayName && athleteResumeData) {
      setHeadingText("HCA is Making Moves");
      setSubheadingText(
        "Now using our new AI-driven resume based profile builder. We've combined the original profile data that you filled out along with your resume.",
      );
      setReviewText(
        "Before we show you your new profile, please review the fields below and make any necessary changes.",
      );
    } else {
      setHeadingText("HCA is Making Moves");
      setSubheadingText("Now using our new AI-driven resume based profile builder.");
      setReviewText(
        "Before we show you your new profile, please review the fields below and make any necessary changes.",
      );
    }
  }, [isSuccessAthleteProfile, athleteProfile, athleteResumeData]);

  const {
    universityList,
    sportList,
    handlePrimarySportChange,
    positions,
    isLoading: isLoadingData,
    isLoadingPositions,
  } = useAthleteProfileBuilderData();

  useEffect(() => {
    if (isLoadingData) return;

    if (universityList) {
      const universityOptions = universityList.map((university) => ({
        id: university.id.toString(),
        name: university.universityName ?? "",
      }));
      updateFormConfigOptions("primaryUniversityId", universityOptions);
    }

    if (sportList) {
      const sportOptions = sportList.map((sport) => ({
        id: sport.id.toString(),
        name: sport.sport,
      }));
      updateFormConfigOptions("primarySportId", sportOptions);
    }
  }, [isLoadingData, universityList, sportList]);

  useEffect(() => {
    if (isLoadingPositions) return;
    if (positions) {
      const positionOptions = positions.map((position) => ({
        id: position.id.toString(),
        name: position.position ?? "",
      }));
      updateFormConfigOptions("primaryPositionId", positionOptions);
    }
  }, [positions, isLoadingPositions]);

  useEffect(() => {
    handlePrimarySportChange(Number(formValues.primarySportId));
  }, [formValues.primarySportId]);

  useEffect(() => {
    if (formValues.primaryUniversityId) {
      const universityName =
        universityList?.find((u) => u.id === Number(formValues.primaryUniversityId))?.universityName ?? "";
      formMethods.setValue("primaryUniversityName", universityName);
    }
    if (formValues.primarySportId) {
      const sportName = sportList?.find((s) => s.id === Number(formValues.primarySportId))?.sport ?? "";
      formMethods.setValue("primarySport", sportName);
    }
    if (formValues.primaryPositionId) {
      const positionName = positions?.find((p) => p.id === Number(formValues.primaryPositionId))?.position ?? "";
      formMethods.setValue("primaryPosition", positionName);
    }
  }, [formValues.primaryUniversityId, formValues.primarySportId, formValues.primaryPositionId]);

  function updateFormConfigOptions(fieldName: keyof AthleteInfo, options: Array<{ id: string; name: string }>) {
    setFormConfigData((prevConfig) => ({
      ...prevConfig,
      [fieldName]: {
        ...prevConfig[fieldName],
        options,
      },
    }));
  }

  useEffect(() => {
    if (isLoadingData) return;

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [isLoadingData]);

  useEffect(() => {
    if (isSuccessAthleteProfile && athleteProfile) {
      // First set the athlete profile as the base
      const baseData = {
        ...athleteProfile,
        primaryPhone:
          athleteProfile.primaryPhone?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ?? "",
        primaryUniversityId: Number(athleteProfile.primaryUniversityId),
        primarySportId: Number(athleteProfile.primarySportId),
        primaryPositionId: Number(athleteProfile.primaryPositionId) || null,
        educationLevel: athleteProfile.educationLevel ?? "",
      };

      // If we have resume data and are onboarding, fill in any blank fields
      if (athleteResumeData) {
        const cleanedResumeData = cleanResumeData(athleteResumeData);
        const mergedData: AthleteInfoEditableFields = { ...baseData };

        // Only copy resume data for fields that are empty in the profile
        Object.keys(cleanedResumeData).forEach((key) => {
          const typedKey = key as keyof AthleteInfoEditableFields;
          if (!baseData[typedKey] && cleanedResumeData[typedKey]) {
            const typedValue = cleanedResumeData[typedKey] as unknown as (typeof baseData)[typeof typedKey];
            if (typedValue !== null) {
              // @ts-expect-error: Do I look like a magician?
              mergedData[typedKey] = typedValue;
            }
          }
        });

        formMethods.reset(mergedData);
      } else {
        formMethods.reset(baseData);
      }
    } else if (athleteResumeData) {
      // If no profile exists, use resume data
      const cleanedResumeData = cleanResumeData(athleteResumeData);
      formMethods.reset(cleanedResumeData);
    }
  }, [athleteResumeData, isOnboarding, isSuccessAthleteProfile, athleteProfile]);

  function cleanResumeData(resumeData: AthleteProfile) {
    const cleanedPhone = resumeData.primaryPhone?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    const cleanedAthleteInfo: AthleteInfoEditableFields = {
      firstName: resumeData.firstName,
      lastName: resumeData.lastName,
      displayName: resumeData.displayName,
      primaryEmail: resumeData.primaryEmail,
      linkedInUrl: resumeData.linkedInUrl,
      primaryPhone: cleanedPhone,
      primaryUniversityId: null,
      primaryUniversityName: "",
      primaryMajor: majorOptions.some((m) => m === resumeData.primaryMajor) ? resumeData.primaryMajor : "",
      primarySport: "",
      primarySportId: null,
      primaryPosition: "",
      primaryPositionId: null,
      educationLevel: educationLevelOptions.some((e) => e === resumeData.educationLevel)
        ? resumeData.educationLevel
        : "",
      graduationYear: resumeData.graduationYear,
      gpa: resumeData.gpa,
      currentLocation: stateOptions.some((s) => s === resumeData.currentLocation) ? resumeData.currentLocation : "",
      workPreference: preferredEmploymentOptions.some((e) => e === resumeData.workPreference)
        ? resumeData.workPreference
        : "",
      workType: preferredWorkEnvironmentOptions.some((e) => e === resumeData.workType) ? resumeData.workType : "",
      experienceYears: resumeData.experienceYears,
      readyToWork: resumeData.readyToWork ?? true,
    };

    return cleanedAthleteInfo;
  }

  // Fields we want to show in the form, excluding system fields
  const fieldsToEdit: (keyof AthleteInfoEditableFields)[] = athleteBasicInfoFieldsToEdit;

  const handleSubmit: SubmitHandler<AthleteInfo> = async (data) => {
    updateAthleteInfo({ info: data });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  if (isLoading) {
    return (
      <Center h="90vh" flexDirection="column" gap={4}>
        <Heading>Loading profile builder...</Heading>
        <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
      </Center>
    );
  }

  return (
    <Page>
      <VStack align="stretch" spacing={[3, 6]} bg="white" p={[6, 12]} borderRadius="lg" shadow="md" maxW="600px">
        <Heading textAlign="center">{headingText}</Heading>
        <Text textAlign={["center", "left"]}>{subheadingText}</Text>
        {athleteResumeData && <Text fontWeight="bold">{reviewText}</Text>}
        <form ref={formRef} onSubmit={formMethods.handleSubmit(handleSubmit)} onKeyDown={handleKeyDown}>
          <DynamicForm<AthleteInfo>
            fieldsToEdit={fieldsToEdit}
            formData={{} as AthleteInfo} // Empty initial data
            formMethods={formMethods}
            formConfig={formConfigData}
          />
          <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
            <Button type="submit" colorScheme="brand" alignSelf="flex-end">
              Save
            </Button>
          </Box>
        </form>
      </VStack>
    </Page>
  );
}
