import { memo, useCallback, useEffect, useRef, useState } from "react";

import { ColDef, GridReadyEvent, RowClassParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { AthleteInfoForAdmin } from "@shared/athleteModels";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Select } from "chakra-react-select";
import { TbX } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

import { columnDefinitions } from "./components/athleteColumns";
import { AthleteOverviewCard } from "./components/AthleteOverviewCard";
import { MobileAthletesView } from "./components/MobileAthletesView";
import { AthleteGridData, AthletesTabProps } from "./types/athletes";
import { useAppStore } from "../../app-store";
import { AwesomeHCASpinner } from "../../components/AwesomeHCASpinner";
import { SearchBar } from "../../components/SearchBar";
import useUrlParameter from "../../hooks/useUrlParameter";
import { getAllAthleteInfo } from "../../services/admin-api";
import { listenToUserConversations } from "../../services/firebase-service";
import { FullConversation, MessagesApi } from "../../services/messages-api";
import { calculateProfileCompletion, simpleDebounce } from "../../utils";

const pageInfoHeight = 0.25;
const gridAreaHeight = `${(1 - pageInfoHeight) * 100}%`;

const deepSearchFields = [
  "displayName",
  "primaryUniversityName",
  "primaryMajor",
  "primarySport",
  "primaryPosition",
  "graduationYear",
  "currentLocation",
  "workPreference",
  "workType",
  "primaryEmail",
  "primaryPhone",
];

const rowClassRules = {
  // "pending-athlete": (params: RowClassParams) => params.data.isPending,
  "active-athlete": (params: RowClassParams) => params.data.profileCompletion > 0,
};

// We aren't currently showing the table in mobile view.
const mobileColumns: string[] = [
  "displayName",
  // "actions"
];

const tableSettings = {
  pagination: true,
  paginationPageSize: 25,
  paginationPageSizeSelector: [15, 25, 50],
};

export const AthletesTab = memo(({ urlParameters }: AthletesTabProps) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const navigate = useNavigate();
  const { setUrlParameter, clearUrlParameter } = useUrlParameter();
  const isMobile = useBreakpointValue({ base: true, sm: false }, { ssr: false });
  const [isDoingInitialLoad, setIsDoingInitialLoad] = useState(true);

  const [allAthletes, setAllAthletes] = useState<AthleteGridData[]>([]);
  const [mobileAthletes, setMobileAthletes] = useState<AthleteGridData[]>([]);
  const [columnDefs] = useState<ColDef[]>(columnDefinitions);
  const [pageHeight, setPageHeight] = useState(0);
  const gridRef = useRef<AgGridReact>(null);
  const [athleteGridInfo, setAthleteGridInfo] = useState<AthleteGridData[]>([]);
  const [conversationMap, setConversationMap] = useState<Map<number, FullConversation>>(new Map());
  // TODO: I think I should keep the conversation map seperate from the athlete grid info...

  const [universityOptions, setUniversityOptions] = useState<{ label: string; value: string }[]>([]);
  const [isLoadingAthlete, setIsLoadingAthlete] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<AthleteGridData | null>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const athleteInfoRef = useRef<HTMLDivElement>(null);
  const [athleteInfoHeight, setAthleteInfoHeight] = useState(0);
  const [quickFilterText, setQuickFilterText] = useState("");
  const searchBarRef = useRef<{ clearInput: () => void }>(null);

  const { data: conversations, refetch: fetchConversations } = useQuery({
    queryKey: ["conversations", userInfo?.id],
    queryFn: () => MessagesApi.getUserConversations(userInfo!.id),
  });

  useEffect(() => {
    if (userInfo) {
      fetchConversations();
      listenToUserConversations(userInfo.id, undefined, fetchConversations);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!conversations) return;
    const conversationMap = new Map<number, FullConversation>();
    conversations?.forEach((conversation) => {
      const recipiantId = conversation.participants.find(
        (participant) => participant.user_id !== userInfo!.id,
      )?.user_id;
      if (recipiantId) {
        conversationMap.set(recipiantId, conversation);
      }
    });
    setConversationMap(conversationMap);
  }, [conversations]);

  useEffect(() => {
    if (isMobile) {
      tableSettings.paginationPageSizeSelector = [15, 25];
      tableSettings.paginationPageSize = 15;
      columnDefs.forEach((column) => {
        if (mobileColumns.includes(column.field as string)) {
          column.hide = false;
        } else {
          column.hide = true;
        }
      });
    } else {
      tableSettings.paginationPageSizeSelector = [15, 25, 50];
      tableSettings.paginationPageSize = 25;
      columnDefs.forEach((column) => {
        column.hide = false;
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (athleteInfoRef.current) {
      const rect = athleteInfoRef.current.getBoundingClientRect();
      setAthleteInfoHeight(rect.height);
    }
  }, [athleteInfoRef, pageHeight, selectedAthlete]);

  const { data: athleteInfo } = useQuery({
    queryKey: ["admin-athletes"],
    queryFn: () => getAllAthleteInfo(),
  });

  const setTableHeight = () => {
    if (pageRef.current) {
      const innerHeight = window.innerHeight;
      const rect = pageRef.current.getBoundingClientRect();
      const newHeight = innerHeight - (rect.top + 65);
      setPageHeight(newHeight);
    }
  };

  useEffect(() => {
    if (athleteInfo) {
      const allAthletes: AthleteGridData[] = athleteInfo.map((athlete) => ({
        ...athlete,
        profileCompletion: calculateProfileCompletion(athlete, "athlete"),
        resumeOpenUrl: null,
        profilePictureUrl: null,
        searchData: deepSearchFields.map((field) => athlete[field as keyof AthleteInfoForAdmin]).join(" "),
        conversation: conversationMap.get(athlete.userId) || null,
      }));
      const filteredAthletes = allAthletes.filter(
        (athlete) => athlete.displayName && athlete.displayName.trim().length > 0,
      );
      setAllAthletes(
        filteredAthletes.sort((a, b) => {
          return b.userId - a.userId;
        }),
      );
      setAthleteGridInfo(filteredAthletes);
      setMobileAthletes(filteredAthletes);
      setUniversityOptions([
        { label: "Any", value: "Any" },
        ...Array.from(
          new Set(filteredAthletes.map((athlete) => athlete.primaryUniversityName).filter((name) => !!name)),
        )
          .map((universityName) => ({
            label: universityName,
            value: universityName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ]);
    }

    setIsDoingInitialLoad(false);
  }, [athleteInfo, conversationMap]);

  useEffect(() => {
    if (pageRef.current && athleteInfo) {
      setTableHeight();
    }
  }, [athleteInfo, pageRef.current]);

  useEffect(() => {
    const processParameters = () => {
      if (!gridRef.current || !allAthletes.length || isDoingInitialLoad) return;

      let filteredAthletes = allAthletes;

      const urlUniversity = urlParameters.university;
      if (urlUniversity && urlUniversity !== "Any") {
        filteredAthletes = allAthletes.filter((athlete) => athlete.primaryUniversityName === urlUniversity);
      }

      const urlAthlete = urlParameters.athlete;
      if (urlAthlete) {
        handleAthleteFocus(parseInt(urlAthlete));
      }

      const urlDeepSearch = urlParameters.deepSearch;
      if (urlDeepSearch) {
        filteredAthletes = filteredAthletes.filter((athlete) =>
          athlete.searchData.toLowerCase().includes(urlDeepSearch.toLowerCase()),
        );
        searchBarRef.current?.clearInput();
      }

      setAthleteGridInfo([...filteredAthletes]);

      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit();
        setQuickFilterText("");
        setIsLoadingAthlete(false);
      }
    };
    if (gridRef.current) {
      processParameters();
    } else {
      const interval = setInterval(() => {
        if (gridRef.current) {
          processParameters();
          clearInterval(interval);
        }
      }, 100);
    }
  }, [urlParameters, allAthletes, isDoingInitialLoad, gridRef.current]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (params.api) {
        params.api.sizeColumnsToFit();
      }
    },
    [athleteGridInfo],
  );

  useEffect(() => {
    const debouncedResize = simpleDebounce(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit();
      }
      setTableHeight();
    }, 150);

    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
      debouncedResize.cancel(); // Cancel any pending debounce calls
    };
  }, []);

  const handleAthleteFocus = async (userId: number | undefined) => {
    setIsLoadingAthlete(true);
    if (!userId) return;
    const athlete = athleteGridInfo.find((athlete) => athlete.userId === userId);
    if (!athlete) return;

    if (athlete.userInfo.files.find((file) => file.category === "profile-picture") && !athlete.profilePictureUrl) {
      try {
        const url = await axios.post("/api/file/presigned-open-url", {
          category: "profile-picture",
          remoteFileName: athlete.userInfo.files.find((file) => file.category === "profile-picture")?.stored_file_name,
        });
        athlete.profilePictureUrl = url.data.presignedUrl;
        setAthleteGridInfo((prev) =>
          prev.map((athlete) =>
            athlete.userId === userId ? { ...athlete, profilePictureUrl: url.data.presignedUrl } : athlete,
          ),
        );
      } catch (error) {
        console.log("error setting athlete profile picture url", error);
      }
    }

    if (athlete.userInfo.files.find((file) => file.category === "resume") && !athlete.resumeOpenUrl) {
      try {
        const url = await axios.post("/api/file/presigned-open-url", {
          category: "resume",
          remoteFileName: athlete.userInfo.files.find((file) => file.category === "resume")?.stored_file_name,
        });
        athlete.resumeOpenUrl = url.data.presignedUrl;
        setAthleteGridInfo((prev) =>
          prev.map((athlete) =>
            athlete.userId === userId ? { ...athlete, resumeOpenUrl: url.data.presignedUrl } : athlete,
          ),
        );
      } catch (error) {
        console.log("error setting athlete resume open url", error);
      }
    }
    setSelectedAthlete(athlete);
    setTimeout(() => {
      setIsLoadingAthlete(false);
    }, 250);
  };

  const handleSearch = useCallback((value: string) => {
    setQuickFilterText(value);
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    setQuickFilterText(value);
  }, []);

  if (isDoingInitialLoad || !athleteInfo) {
    return <AwesomeHCASpinner fullScreen={true} />;
  }

  if (isMobile) {
    let filteredMobileAthletes = mobileAthletes;
    if (urlParameters.university && urlParameters.university !== "Any") {
      filteredMobileAthletes = filteredMobileAthletes.filter(
        (athlete) => athlete.primaryUniversityName === urlParameters.university,
      );
    }

    return (
      <MobileAthletesView
        athletes={filteredMobileAthletes}
        universityOptions={universityOptions}
        setUrlParameter={setUrlParameter}
        urlParameters={urlParameters}
      />
    );
  }

  return (
    <Flex flexDir={"column"} h={pageHeight} ref={pageRef}>
      <Flex direction={"row"} pb={4} h={`${pageInfoHeight * 100}%`} minHeight={256} justify="space-between" gap={4}>
        <Flex flex={1} flexDirection="column" pl={1} pr={4} pt={4}>
          <VStack w="100%" h="100%" display="flex" justifyContent={"space-between"} alignItems={"flex-start"}>
            <HStack w="100%" justifyContent={"space-between"} gap={4}>
              <Flex flex={1} w="100%">
                {urlParameters.university && urlParameters.university !== "Any" ? (
                  <HStack w="100%" justifyContent={"flex-start"} gap={2}>
                    <IconButton
                      icon={<TbX size={24} />}
                      aria-label="Clear"
                      onClick={() => clearUrlParameter("university")}
                    />
                    <Text fontWeight={"bold"}>{urlParameters.university}</Text>
                  </HStack>
                ) : (
                  <Box w="100%">
                    <Select
                      options={universityOptions}
                      placeholder="Select a university"
                      onChange={(e) => setUrlParameter("university", e?.value || "Any")}
                      value={
                        urlParameters.university && urlParameters.university !== "Any"
                          ? { label: urlParameters.university, value: urlParameters.university }
                          : null
                      }
                    />
                  </Box>
                )}
              </Flex>
              <HStack flex={1} justifyContent={"space-between"}>
                <Heading size="md">Total Athletes:</Heading>
                <Text>{athleteGridInfo.length}</Text>
              </HStack>
            </HStack>
            <VStack w="100%" alignItems={"flex-start"}>
              {urlParameters.deepSearch && (
                <Text color="brand.primary">
                  <em>
                    Filtering results on: {urlParameters.deepSearch}, {athleteGridInfo.length} results.
                  </em>
                </Text>
              )}
              <HStack w="100%" justifyContent={"space-between"}>
                <SearchBar
                  ref={searchBarRef}
                  placeholder={urlParameters.deepSearch ? "Search filtered list" : "Search for athletes"}
                  onClick={handleSearch}
                  size="lg"
                  onChange={handleSearchChange}
                />
                {urlParameters.deepSearch ? (
                  <Button size="lg" variant="solid" colorScheme="red" onClick={() => clearUrlParameter("deepSearch")}>
                    Clear
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    variant="outline"
                    colorScheme="red"
                    onClick={() => setUrlParameter("deepSearch", quickFilterText)}
                  >
                    Deep Search
                  </Button>
                )}
              </HStack>
            </VStack>
          </VStack>
        </Flex>
        <Flex
          flex={[2, 1]}
          flexDirection="column"
          ref={athleteInfoRef}
          bg={"white"}
          borderRadius={"lg"}
          shadow={"md"}
          hideBelow="sm"
        >
          {isLoadingAthlete ? (
            <AwesomeHCASpinner fullScreen={true} />
          ) : selectedAthlete ? (
            <AthleteOverviewCard
              athlete={selectedAthlete}
              athleteInfoHeight={athleteInfoHeight}
              isMobile={isMobile || false}
              onNavigate={(userId) => navigate(`/athlete/profile/${userId}`)}
            />
          ) : (
            <Center h="100%" w="100%">
              <Heading textAlign={"center"} size="md">
                Select an athlete to see their information.
              </Heading>
            </Center>
          )}
        </Flex>
      </Flex>
      <Flex h={gridAreaHeight}>
        {/* <div>HI {JSON.stringify(columnDefs)}</div> */}
        <div className="ag-theme-quartz" style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={[...athleteGridInfo]}
            columnDefs={columnDefs}
            defaultColDef={{ sortable: true, filter: true, resizable: true }}
            rowClassRules={rowClassRules}
            onRowClicked={(event) => setUrlParameter("athlete", event.data.userId.toString())}
            quickFilterText={quickFilterText}
            suppressPaginationPanel={isMobile}
            {...tableSettings}
          />
        </div>
      </Flex>
    </Flex>
  );
});
