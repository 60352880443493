import { useEffect, useState } from "react";

import { ColDef } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Icon, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { AdminFullAthleteProfileInfo } from "../../../../../shared/models";
import { initializeAgGrid } from "../../ag-grid-setup";
import { SearchBar } from "../../components/SearchBar";
import { useUniversity } from "../../hooks/useUniversity";
import { getProfile } from "../../services/athlete-api";
import { getAthletesByUniversityId } from "../../services/university-api";
import { ActionButtonsCellRendererParams } from "../admin/types/athletes";

initializeAgGrid();

const InternPage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [universityId, setUniversityId] = useState<number | null>(null);
  const [allAthleteData, setAllAthleteData] = useState<AdminFullAthleteProfileInfo[]>([]);
  const [searchText, setSearchText] = useState("");
  const { university } = useUniversity(universityId);

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isFetching: isFetchingProfile,
  } = useQuery({
    queryKey: ["profileData"],
    queryFn: getProfile,
  });

  useEffect(() => {
    if (profileData) {
      setUniversityId(profileData.universityId);
    }
  }, [profileData]);

  const {
    data: athleteProfiles,
    isLoading: isProfilesLoading,
    isFetching: isFetchingProfiles,
  } = useQuery({
    queryKey: ["profiles", universityId],
    queryFn: () => getAthletesByUniversityId(universityId!),
    enabled: !!universityId,
  });

  useEffect(() => {
    if (athleteProfiles) {
      const allAthletes = athleteProfiles.map((athlete) => ({
        ...athlete,
      }));
      setAllAthleteData(allAthletes);
    }
  }, [athleteProfiles]);

  const columnDefs: ColDef<AdminFullAthleteProfileInfo>[] = [
    {
      headerName: "Display Name",
      field: "displayName",
      flex: 2,
      minWidth: 150,
      filter: true,
      cellRenderer: (params: ActionButtonsCellRendererParams) => {
        const [isHovered, setIsHovered] = useState(false);
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            <Link href={`/athlete/profile/${params.data.userId}`}>{params.data.displayName}</Link>
            {isHovered && <Icon as={ArrowForwardIcon} transform="rotate(-45deg)" fontSize={"lg"} />}
          </Box>
        );
      },
    },
    { headerName: "Sport", field: "primarySport", filter: true, hide: isMobile },
    { headerName: "Email", field: "emailAddress", hide: isMobile },
    { headerName: "Phone Number", field: "phoneNumber", hide: isMobile },
    {
      headerName: "Status",
      field: "isPending",
      cellRenderer: (params: ActionButtonsCellRendererParams) => {
        return params.value ? "Unclaimed" : "Claimed";
      },
    },
  ];

  if (isProfileLoading || isProfilesLoading || isFetchingProfile || isFetchingProfiles) {
    return <div>Loading...</div>;
  }

  const totalAthletes = allAthleteData.length;
  const claimedAthletes = allAthleteData.filter((athlete) => !athlete.isPending).length;
  const unclaimedAthletes = totalAthletes - claimedAthletes;
  const percentageClaimed = ((claimedAthletes / totalAthletes) * 100).toFixed(2);

  return (
    <Flex direction={"column"} gap={4}>
      {university && (
        <Box bg="brand.primary" color="white" p={4} borderRadius="md" boxShadow="md" textAlign={["center"]}>
          <Heading size={["md", "lg"]}>{university.universityName} Athletes</Heading>
        </Box>
      )}
      <Flex
        direction={["column", "row"]}
        gap={2}
        justifyContent={"space-between"}
        p={[4, 8]}
        bg={"white"}
        borderRadius={"md"}
        shadow={"md"}
      >
        <Text>
          <b>Total Athletes:</b> {totalAthletes}
        </Text>
        <Text>
          <b>Claimed Athletes:</b> {claimedAthletes}
        </Text>
        <Text>
          <b>Unclaimed Athletes:</b> {unclaimedAthletes}
        </Text>
        <Text>
          <b>Percentage Claimed:</b> {percentageClaimed}%
        </Text>
      </Flex>
      <Box w={"full"} px={0.5}>
        <SearchBar placeholder="Search" size="lg" onChange={(value: string) => setSearchText(value)} hideButton />
      </Box>
      <Flex>
        <div className="ag-theme-quartz" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            rowData={allAthleteData}
            columnDefs={columnDefs}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            quickFilterText={searchText}
            pagination={!isMobile}
            paginationPageSize={25}
            paginationPageSizeSelector={[15, 25, 50]}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default InternPage;
