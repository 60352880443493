import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Box, Center, Heading, Spinner, VStack, Link, Grid, GridItem } from "@chakra-ui/react";
import { AthleteSignUpInfo } from "@shared/athleteModels";
import { useQuery } from "@tanstack/react-query";

import { UniversityAthleteCount } from "../../../../../shared/models";
import { getHCAInsights } from "../../services/admin-api";

export interface HCAInsights {
  universityAthleteCounts: UniversityAthleteCount[];
  allAthleteSignups: AthleteSignUpInfo[];
}

interface CellRendererParams extends ICellRendererParams {
  data: UniversityAthleteCount;
}

export const InsightsTab = () => {
  const { data: insights, isLoading } = useQuery<HCAInsights>({
    queryKey: ["insights"],
    queryFn: () => getHCAInsights(),
  });

  if (isLoading) {
    return (
      <Center w={"full"} h={"full"}>
        <Spinner />
      </Center>
    );
  }

  type GridData = UniversityAthleteCount[] | AthleteSignUpInfo[] | undefined;

  const renderGrid = (title: string, data: GridData, columns: ColDef[]) => {
    if (!data) {
      return null;
    }
    return (
      <Box w="full">
        <Heading size={["md"]} mb={2}>
          {title}
        </Heading>
        <Box className="ag-theme-quartz" style={{ width: "100%", height: "700px" }}>
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            defaultColDef={{ sortable: true, filter: true, resizable: true }}
            pagination={true}
            paginationAutoPageSize={true}
          />
        </Box>
      </Box>
    );
  };

  return (
    <VStack w={"100%"} overflowY={"auto"} h={["100%", "80vh"]} spacing={4}>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(6, 1fr)", "2xl": "repeat(12, 1fr)" }}
        gap={4}
        w="full"
      >
        <GridItem colSpan={{ base: 1, xl: 6, "2xl": 3 }}>
          {renderGrid("University Athlete Counts", insights?.universityAthleteCounts, [
            {
              headerName: "University",
              field: "universityName",
              flex: 2,
              cellRenderer: (params: CellRendererParams) => {
                // build a link to the tab=athletes&university=universityName
                const url = new URL(`/admin`, window.location.origin);
                url.searchParams.set("tab", "athletes");
                url.searchParams.set("university", params.data.universityName);
                return <Link href={url.toString()}>{params.data.universityName}</Link>;
              },
            },
            { headerName: "Active Athletes", field: "activeAthleteCount", flex: 1 },
          ])}
        </GridItem>
        <GridItem colSpan={{ base: 1, xl: 6, "2xl": 9 }}>
          {renderGrid("All Athlete Signups", insights?.allAthleteSignups, [
            { headerName: "Display Name", field: "athleteInfo.displayName", flex: 2 },
            { headerName: "Sign Up Email", field: "emailAddress", flex: 2 },
            { headerName: "Primary Email", field: "athleteInfo.primaryEmail", flex: 2 },
            { headerName: "University", field: "athleteInfo.primaryUniversityName", flex: 2 },
            {
              headerName: "Signup Date",
              field: "clerkInfo.createdAt",
              flex: 1,
              valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString() : "No Clerk Record",
            },
            {
              headerName: "Last Sign In",
              field: "clerkInfo.lastSignInAt",
              flex: 1,
              valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString() : "No Clerk Record",
            },
            {
              headerName: "Athlete Profile Created",
              field: "athleteInfo.createdAt",
              flex: 1,
              valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString() : "No Athlete Profile",
            },
            {
              headerName: "Last Profile Update",
              field: "athleteInfo.updatedAt",
              flex: 1,
              valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString() : "No Athlete Profile",
            },
          ])}
        </GridItem>
      </Grid>
    </VStack>
  );
};
