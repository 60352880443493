import locationData from "@shared/locationData.json";
import majorsData from "@shared/majorsData.json";

import { AthleteInfo } from "../../../../shared/athleteModels";
import { Position, Sport, University } from "../../../../shared/models";
import { FormFieldConfig, FormFieldType } from "../components/DynamicForm";

export const majorOptions = majorsData.majors.sort((a, b) => a.localeCompare(b));
export const preferredEmploymentOptions = ["Full time", "Part time", "Contract", "Internship"];
export const preferredWorkEnvironmentOptions = ["Any", "In-person", "Remote", "Hybrid"];

export type AthleteInfoEditableFields = Omit<AthleteInfo, "id" | "userId" | "createdAt" | "updatedAt">;
export type AthleteInfoKeysArray = [keyof AthleteInfoEditableFields][];

export const athleteBasicInfoFieldsToEdit: (keyof AthleteInfoEditableFields)[] = [
  "firstName",
  "lastName",
  "displayName",
  "primaryEmail",
  "primaryPhone",
  "linkedInUrl",
  "primaryUniversityId",
  "primaryMajor",
  "primarySportId",
  "primaryPositionId",
  "educationLevel",
  "graduationYear",
  "gpa",
  "currentLocation",
  "experienceYears",
  "workPreference",
  "workType",
  "readyToWork",
];

export const stateOptions = locationData.locations.sort((a, b) => a.localeCompare(b)) as string[];
export const educationLevelOptions = [
  "High School",
  "Some College",
  "Associate's",
  "Bachelor's",
  "Master's",
  "Doctorate",
];

export const athleteFormConfig: Record<keyof AthleteInfo, FormFieldConfig<AthleteInfo[keyof AthleteInfo]>> = {
  id: { label: "ID", helperText: "Your unique ID", type: FormFieldType.TEXT, required: false },
  userId: { label: "User ID", helperText: "Your user ID", type: FormFieldType.TEXT, required: false },
  firstName: { label: "First Name", helperText: "Your first name", type: FormFieldType.TEXT, required: true },
  lastName: { label: "Last Name", helperText: "Your last name", type: FormFieldType.TEXT, required: true },
  displayName: { label: "Display Name", helperText: "Your display name", type: FormFieldType.TEXT, required: true },
  primaryEmail: { label: "Email", helperText: "Your primary email address", type: FormFieldType.TEXT, required: true },
  primaryPhone: { label: "Phone", helperText: "Your primary phone number", type: FormFieldType.TEXT, required: true },
  linkedInUrl: { label: "LinkedIn URL", helperText: "Your LinkedIn URL", type: FormFieldType.TEXT, required: false },
  primaryUniversityName: {
    label: "University",
    helperText: "Your university",
    type: FormFieldType.TEXT,
    required: false,
  },
  primaryUniversityId: {
    label: "University Name",
    tooltip: "Current university or where you graduated from",
    helperText: "Name of the university you attended",
    type: FormFieldType.SELECT,
    options: [],
    required: true,
    accessor: (data: University | null) => data?.universityName ?? "",
  },
  primaryMajor: {
    label: "Major",
    helperText: "Your major in university",
    type: FormFieldType.SELECT,
    options: majorOptions,
    required: true,
  },
  primarySport: { label: "Primary Sport", helperText: "Your main sport", type: FormFieldType.TEXT, required: false },
  primarySportId: {
    label: "Primary Sport",
    helperText: "Your main sport",
    type: FormFieldType.SELECT,
    options: [],
    required: true,
    accessor: (data: Sport | null) => data?.sport ?? "",
  },
  primaryPosition: {
    label: "Primary Position",
    helperText: "Your position",
    type: FormFieldType.TEXT,
    required: false,
  },
  primaryPositionId: {
    label: "Position",
    helperText: "Your position",
    type: FormFieldType.SELECT,
    options: [],
    required: false,
    accessor: (data: Position | null) => data?.position ?? "",
  },
  educationLevel: {
    label: "Education Level",
    helperText: "Your education level",
    type: FormFieldType.SELECT,
    options: educationLevelOptions,
    required: true,
  },
  graduationYear: {
    label: "Graduation Year",
    helperText: "Year of graduation",
    type: FormFieldType.NUMBER,
    required: true,
  },
  gpa: { label: "GPA", helperText: "Your GPA", type: FormFieldType.NUMBER, required: true },
  experienceYears: {
    label: "Years of Professional Experience",
    helperText: "Professional experience in years",
    type: FormFieldType.NUMBER,
    required: false,
  },
  workPreference: {
    label: "Work Preference",
    helperText: "Preferred work arrangement",
    type: FormFieldType.SELECT,
    options: preferredEmploymentOptions,
    required: false,
  },
  workType: {
    label: "Work Type",
    helperText: "Type of work",
    type: FormFieldType.SELECT,
    options: preferredWorkEnvironmentOptions,
    required: false,
  },
  currentLocation: {
    label: "Current Location",
    helperText: "Where you are located",
    type: FormFieldType.SELECT,
    options: stateOptions,
    required: false,
  },
  readyToWork: {
    label: "Ready to Work",
    helperText: "Are you ready to start working?",
    type: FormFieldType.CHECKBOX,
    required: false,
  },
  createdAt: { label: "Created", helperText: "Profile creation date", type: FormFieldType.DATE },
  updatedAt: { label: "Updated", helperText: "Last update date", type: FormFieldType.DATE },
};
