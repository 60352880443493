import { useState } from "react";

import { ColDef } from "@ag-grid-community/core";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, IconButton, Link, Text } from "@chakra-ui/react";

import { ActionButtons } from "./ActionButtons";
import ActionsFilter from "./ActionsFilter";
import { ActionButtonsCellRendererParams } from "../types/athletes";

export const columnDefinitions: ColDef[] = [
  {
    headerName: "Display Name",
    field: "displayName",
    flex: 2,
    minWidth: 150,
    filter: true,
    cellRenderer: (params: ActionButtonsCellRendererParams) => {
      const [isHovered, setIsHovered] = useState(false);
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Text>{params.data.displayName}</Text>
          {isHovered && (
            <IconButton
              as={Link}
              rounded="full"
              href={`/athlete/profile/${params.data.userId}`}
              icon={<ArrowForwardIcon transform="rotate(-45deg)" fontSize="lg" />}
              aria-label="Go to athlete profile"
              size="sm"
              variant="outline"
              colorScheme="purple"
            />
          )}
        </Box>
      );
    },
  },
  {
    headerName: "Signed Up",
    field: "createdAt",
    flex: 1,
    minWidth: 50,
    filter: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  {
    headerName: "Last Updated",
    field: "updatedAt",
    flex: 1,
    minWidth: 50,
    filter: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  { headerName: "University", field: "primaryUniversityName", flex: 2, minWidth: 150, filter: true },
  { headerName: "Major", field: "primaryMajor", flex: 2, minWidth: 100, filter: true },
  { headerName: "Year", field: "graduationYear", flex: 1, minWidth: 50, filter: true },
  { headerName: "Sport", field: "primarySport", flex: 1, minWidth: 100, filter: true },
  // { headerName: "Position", field: "primaryPosition", flex: 1, minWidth: 100, filter: true },
  // {
  //   headerName: "Completion",
  //   field: "profileCompletion",
  //   flex: 1,
  //   minWidth: 50,
  //   filter: true,
  //   valueFormatter: (params) => `${params.value}%`,
  // },
  {
    headerName: "Actions",
    field: "actions",
    flex: 2,
    minWidth: 100,
    sortable: false,
    filter: ActionsFilter,
    cellRenderer: (params: ActionButtonsCellRendererParams) => {
      return <ActionButtons {...params.data} />;
    },
  },
];
