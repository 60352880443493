import { AthleteInfoForAdmin } from "@shared/athleteModels";
import axios from "axios";

import { AppConfig } from "./app-config-service";
import { AdminFullAthleteProfileInfo } from "../../../../shared/models";
import { HCAInsights } from "../pages/admin/InsightsTab";

export const getAllAthleteProfiles = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profiles`);

  return data as AdminFullAthleteProfileInfo[] | undefined;
};

export const getAllAthleteInfo = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/admin/athletes`);

  return data as AthleteInfoForAdmin[] | undefined;
};

export const getHCAInsights = async () => {
  const { data: universityAthleteCounts } = await axios.get(`${AppConfig.apiUrl}/api/admin/university-athlete-counts`);
  const { data: allAthleteSignups } = await axios.get(`${AppConfig.apiUrl}/api/admin/all-signups`);

  return { universityAthleteCounts: universityAthleteCounts, allAthleteSignups: allAthleteSignups } as HCAInsights;
};
